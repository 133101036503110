import { getSessionType } from "../../../helpers/clientListHelper";
import { ClientListResponse, GenderType } from "../../../types/clientListTypes";
import {
  ClientListFilterPresetEnum,
  ClientListRequest,
  ClientListSortFieldEnum,
} from "../clientListApi";

export type ClientModel = {
  id: number;
  buyer_name: string | undefined;
  given_name: string | undefined; // given by advisor
  buyer_nickname: string;
  buyer_app_context: string;  // for example "pg"
  dob: string | null;
  dob_order: number| null;
  given_dob: string | null; // given by advisor
  given_dob_order: string | null; // given by advisor
  orders_count: number;
  acted_at: string | null;
  unread_messages_count: number;
  last_message_at: string | null;
  favorite: boolean;
  last_order_type: string;
  gender: string;
  last_order_submitted_at: string;
  buyer_avatar_url: string;
  messages_count: number;
  total_paid: string;
  total_bonus_paid: string;
};

export type MyClientsResponse = {
  clients: ClientModel[];
  total_count?: number;
};

export const mapToClientListResponse = (
  response: MyClientsResponse
): ClientListResponse => {
  return {
    clients: (response?.clients || []).map((client) => {
      return {
        clientId: client.id,
        clientAlias: client.buyer_nickname,
        clientNameFromNote: client.given_name,
        hasUnreadMessages: client.unread_messages_count > 0,
        lastSessionEndTime: client.last_order_submitted_at,
        lastInstantMessage: client.last_message_at,
        sessionCount: client.orders_count,
        isFavoriteClient: client.favorite,
        lastSessionType: getSessionType(client.last_order_type),
        dobOrder: client.dob_order||10,
        gender: client.gender as GenderType,
        dob: client.dob && new Date(client.dob) || null,
        givenDob: client.given_dob && new Date(client.given_dob) || null,
        avatarUrl: client.buyer_avatar_url,
        totalPaid: parseFloat(client.total_paid),

        // ignore for now
        
        expertGuid: "",
        isScheduledMessageEnabled: false,
        lastInstantMessageXDayAgo: null,
        lastScheduledMessageCreated: null,
        lastScheduledMessageDate: null,
        lastSessionDurationInMinutes: null,
        lastSessionId: null,
        lastSessionStartTime: null,
        
        tag: null,
      };
    }),
    totalCount: response?.total_count || 0,
  };
};

export const mapToGetClientListParams = (request: ClientListRequest): any => {
  // TODO: extend this mapping when API will be ready
  return {
    section: mapFilterPresetToSection(request.filterPreset),
    sort_by: mapSortFieldToSortBy(request.sortField),
    sort_type: request.sortingOrder,
    search_by: request.searchText||undefined,
    page: request.pageNumber,
    limit_per_page: request.rowsPerPage,
  };
};

interface Dictionary {
  [Key: number]: string;
}

const mapFilterPresetToSection = (
  filterPreset: ClientListFilterPresetEnum
): string => {
  const mapping: Dictionary = {
    [ClientListFilterPresetEnum.PotentialClients]: "potential_clients",
    [ClientListFilterPresetEnum.Blocked]: "blocked_clients",
    [ClientListFilterPresetEnum.Past]: "past_clients",
    [ClientListFilterPresetEnum.New]: "new_clients",
    [ClientListFilterPresetEnum.RecentlyContacted]: "recent_contacts",
    [ClientListFilterPresetEnum.KasambaSuggestions]: "suggestion_clients",
    [ClientListFilterPresetEnum.Favorite]: "favorite_clients",
    [ClientListFilterPresetEnum.Everyone]: "all_clients",
  };

  return mapping[filterPreset] || "all_clients";
};

function mapSortFieldToSortBy(sortField: ClientListSortFieldEnum) {
  const mapping: Dictionary = {
    [ClientListSortFieldEnum.ClientAlias]: "nickname",
    [ClientListSortFieldEnum.ClientId]: "buyer_id",
    [ClientListSortFieldEnum.DOB]: "dob",
    [ClientListSortFieldEnum.SessionCount]: "orders_count",
    [ClientListSortFieldEnum.LastSessionEndTime]: "last_order_submitted_at",
    [ClientListSortFieldEnum.LastInstantMessage]: "last_message_at",
  };

  return mapping[sortField];
}
