import api from "./api";
import { NotesResponse } from "./noteApi";
import axios from "axios";
import { CANCEL } from "redux-saga";
import { FontSize, Ringing } from "../../types/appSettingsTypes";
import {
  ConnectionConfig,
  MyAdvisorResponse,
  mapToCurrentUserResponse,
} from "./models/my-advisor";
import { PubNubConfig } from "../../types/pubNubTypes";
import { TokenType } from "../../types/authorizationTypes";
import { LoginResponse, MyAccountResponse } from "./models/loginResponse";
import { MyAvailabilityResponse } from "./models/my-availability";
import { AccountState } from "../../types/userTypes";
import { GenderType } from "../../types/clientListTypes";

export type CurrentUserResponse = {
  id: number;
  name: string;
  email: string;
  fee: number;
  avatarUrl: string;
  showOnboardingTooltips: boolean;
  showReleaseNotesMark: boolean;
  volume: number | null;
  ringing: Ringing | null;
  fontSize: FontSize | null;
  antiForgeryToken: string;
  lastViewedOnboardingTooltip: number | null;
  managerOfVipAccounts: boolean;
  lastViewedTutorialSlide: number | null;
  isAutoChatMessageEnabled: boolean;
  autoChatMessageText: string;
  shownGroupMessages: boolean;
  groupMessagesTooltipViewed: boolean;
  advisorBalanceUrl: string;
  isAvailable: boolean;
  state: AccountState;
  chatModeUntil: string | null;
  isBusy: boolean;

  advisorId: number;
  advisorModes: string[];
  creditBalance: number | null;
  currentPromotion: string | null;
  emailAddress: string;
  gender: string;
  language: string;
  lastOrderDate: string;
  lastPurchaseDate: string;
  signupDate: string;
  ordersCount: number;
  purchasesCount: number;
  totalPurchaseFeesPaid: number;
  totalPurchased: number;
  score: number;
};

export type ClientDetailResponse = {
  name: string;
  lastConsulted: Date | null;
  notes: NotesResponse;
  isMessagingAvailable: boolean;
  isFavorite: boolean | null;
};

export type AdvisorStartChatResponse = {
  order_id: string;
  chat_id: string;
  typing_timeout: number;
  buyer_id: number;
  pubnub: PubNubConfig;
  channel_id: string;
  buyer_avatar_url: string;
  buyer_nickname: string;
  gender: GenderType;
  date_of_birth: string;
  analytics: any;
  duration: number;
  tryout: boolean;
  notification_text: string;
  missed_call_notification_text: string;
};

export type AdvisorAcceptChatResponse = {
  last_order_submitted_at: string;
  favorite: boolean | null;
};

export type ClientSettingsResponse = {
  mba_logger_secret: string;
  mba_logger_endpoint: string;
};

export interface SignInErrorResponse {
  errors: Errors;
  full_messages: string[];
}

export interface Errors {
  email: string[];
}

export function getIsExpertActive(): Promise<boolean> {
  // TODO: replace this MOCK
  return Promise.resolve(true);
  // return api
  //   .get<boolean>("/api/experts/me/active")
  //   .then((response) => response.data);
}

export function signIn(
  email: string,
  password: string,
  remember_me: boolean,
): Promise<LoginResponse> {
  return api
    .post("/users/sign_in", { user: { email, password, remember_me } })
    .then((response) => response.data);
}

export function getMyAccount(): Promise<MyAccountResponse> {
  return api.get("/my/account").then((response) => response.data);
}

export function socialSignIn(
  token: string,
  tokenType: TokenType
): Promise<LoginResponse> {
  let user: any;
  if (TokenType.Google === tokenType) {
    user = { user: { google_id_token: token, intention: "advisor" } };
  } else {
    user = { user: { facebook_token: token, intention: "advisor" } };
  }
  return api.post("/users", user).then((response) => {
    return response.data;
  });
}

export function sendResetPassword(email?: string): Promise<any> {
  return api
    .post("/users/password", { user: { email } })
    .then((response) => response.data);
}

export function signOut(): Promise<any> {
  return api.delete("/users/logout").then((response) => response.data);
}

export async function setIsAvailable(
  isAvailable: boolean,
  token?: string
): Promise<MyAvailabilityResponse> {
  return api
    .put<MyAvailabilityResponse>("/my/availability", {
      advisor: { is_available: isAvailable, wem_voip_token: token },
    })
    .then((response) => response.data);
}

export function setChatMode(
  isEnabled: boolean,
  token?: string
): Promise<MyAdvisorResponse> {
  return api
    .put<MyAdvisorResponse>("/my/advisor", {
      advisor: {
        extend_live_chat_mode: isEnabled,
        wem_voip_token: token,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data;
    });
}

export function subscribeNotification(token?: string): Promise<any> {
  return api.put("/my/account", {
    user: {
      fcm_push_token: token,
    },
  });
}

export function queryAdvisorIncomingChat(): Promise<AdvisorStartChatResponse> {
  return api.get<AdvisorStartChatResponse>("/my/chat").then((response) => response.data);
}
export function acceptChat(): Promise<AdvisorAcceptChatResponse> {
  return api.put<AdvisorAcceptChatResponse>("/my/chat/accept").then((response) => response.data);
}

export function getCurrentUser(): Promise<CurrentUserResponse> {
  return api
    .get<MyAdvisorResponse>("/my/advisor")
    .then((response) => response.data)
    .then((data) => mapToCurrentUserResponse(data));
}

export function getClientSettings(): Promise<ClientSettingsResponse> {
  return api
    .get<ClientSettingsResponse>("/client_settings")
    .then((response) => response.data);
}

export function getConnectionConfig(): Promise<ConnectionConfig> {
  return api
    .get<ConnectionConfig>("/my/advisor/connection_config")
    .then(response => response.data);
}

// old endpoints

export function setIsAutoChatMessageEnabled(value: boolean): Promise<any> {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  let request: any = api.put(
    "/api/experts/me/settings/isautochatmessageenabled/",
    { value: value },
    { cancelToken: source.token }
  );
  request[CANCEL] = () => source.cancel(); // cancel request if saga is cancelled (takeLatest for example)
  return request;
}

export function setShowOnboardingTooltips(value: boolean): Promise<any> {
  return api.put("/api/experts/me/settings/showonboardingtooltips/", {
    value: value,
  });
}

export function setGroupMessageTooltipViewed(): Promise<any> {
  return api.put("/api/experts/me/settings/groupmessagestooltipviewed/");
}

export function setReleaseNotesViewed(version: string): Promise<any> {
  return api.put(`/api/experts/me/settings/setreleasenotesviewed/`, {
    value: version,
  });
}

export function saveVolume(value: number): Promise<any> {
  return api.put("/api/experts/me/settings/volume", { value: value });
}

export function saveRinging(value: Ringing): Promise<any> {
  return api.put("/api/experts/me/settings/ringing", { value: value });
}

export function saveFontSize(value: FontSize): Promise<any> {
  return api.put("/api/experts/me/settings/fontsize", { value: value });
}

export function saveLastViewedOnboardingTooltip(value: number): Promise<any> {
  return api.put("/api/experts/me/settings/LastViewedOnboardingTooltip", {
    value: value,
  });
}

export function saveLastViewedTutorialSlide(value: number): Promise<any> {
  return api.put("/api/experts/me/settings/lastviewedtutorialslide", {
    value: value,
  });
}
