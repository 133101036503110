import React, { useState } from "react";
import { useRef } from "react";
import { connect, ConnectedProps } from "react-redux";
import { AppState } from "../../store";
import EmojiIcon from "../atoms/EmojiIcon";
import MessageItem from "../atoms/MessageItem";
import EmojiPicker from "./EmojiPicker";
import "./Messages.scss";
import { useEffect } from "react";
import {
  loadClientMessages,
  resetMessagesClientData,
} from "../../slices/clientSlice";
import Spinner from "../atoms/Spinner";
import {
  sendMessage,
  loadScheduleMessage,
  scheduleMessageLoaded,
  loadMessageTemplates,
} from "../../actions/messageActions";
import "draft-js/dist/Draft.css";
import { Editor, EditorState, ContentState, Modifier } from "draft-js";
import { MessageItemData } from "../../types/clientTypes";
import MessageTemplatePopup from "./MessageTemplatesPopup";
import useComponentVisible from "../../helpers/hooks";
import { MessageTemplateData } from "../../types/messageTypes";
import {
  MessageExtra,
  MessagePromotionInfo,
} from "../../services/api/messagesApi";
import MessagePromotionPopup from "./MessagePromotionPopup";
import PromotionMessageButton from "../atoms/buttons/PromotionMessageButton";
import { replaceClientName } from "../../helpers/utils";
import BirthdayIcon from "../atoms/ClientList/BirthdayIcon";
import { selectedClientDobOrderSelector } from "../../selectors/clientSelectors";
import { formatDate, months } from "../../helpers/dateFormatHelper";
import { isImpersonatedSelector } from "../../slices/userSlice";

type MessagesProps = {
  clientId: number;
};

const viewedConvertedMessagesIdDicKey = "viewedConvertedMessagesIdDic";

const getLastConvertedMessageIdFromStorage = (clientId: number) => {
  try {
    const viewedConvertedMessagesIdDic: { [key: number]: number } = JSON.parse(
      localStorage.getItem(viewedConvertedMessagesIdDicKey) || "{}"
    );
    return viewedConvertedMessagesIdDic[clientId];
  } catch {}
};

const saveLastConvertedMessageIdToStorage = (
  clientId: number,
  value: number
) => {
  try {
    const viewedConvertedMessagesIdDic: { [key: number]: number } = JSON.parse(
      localStorage.getItem(viewedConvertedMessagesIdDicKey) || "{}"
    );
    viewedConvertedMessagesIdDic[clientId] = value;
    localStorage.setItem(
      viewedConvertedMessagesIdDicKey,
      JSON.stringify(viewedConvertedMessagesIdDic)
    );
  } catch {}
};

const Messages = ({
  messages,
  expertAvatarUrl,
  expertName,
  resetMessagesClientData,
  loadClientMessages,
  nextPage,
  isMessagingAvailable,
  isMessagesLoading,
  sendMessage,
  clientName,
  clientTag,
  clientNoteName,
  lastConvertedMessageId,
  loadScheduleMessage,
  scheduledMessage,
  scheduleMessageLoaded,
  loadMessageTemplates,
  clientHasActivePromotion,
  clientId,
  dobOrder,
  birthDate,
  isPotential,
  isImpersonated
}: PropsFromRedux & MessagesProps) => {
  const [lastLoadedMessageDiv, setLastLoadedMessageDiv] =
    useState<HTMLDivElement>();
  const messagesEl = useRef<HTMLDivElement>(null);
  const loadMoreEl = useRef<HTMLDivElement>(null);
  const observer = useRef<IntersectionObserver>();
  const [isEmojiOpened, setIsEmojiOpened] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const editor = useRef<Editor>(null);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const {
    ref,
    isComponentVisible: isTemplatesPopupOpened,
    setIsComponentVisible: setIsTemplatesPopupOpened,
  } = useComponentVisible(false);
  const [selectedTemplate, setSelectedTemplate] =
    useState<MessageTemplateData>();
  const [isPromotionPopupOpened, setIsPromotionPopupOpened] = useState(false);

  const [lastViewedConvertedMessageId, setLastViewedConvertedMessageId] =
    useState<number | undefined>();

  useEffect(() => {
    //document.querySelector(".public-DraftEditor-content")?.setAttribute("data-enable-grammarly", "true");
    //document.querySelector(".public-DraftEditor-content")?.setAttribute("data-gram", "true");
    resetMessagesClientData();
    loadScheduleMessage(clientId);
    loadClientMessages(clientId, 10, 0);
    if (isMessagingAvailable) {
      loadMessageTemplates();
    }
    setLastViewedConvertedMessageId(
      getLastConvertedMessageIdFromStorage(clientId)
    );
    return () => {
      resetMessagesClientData();
    };
  }, [clientId, isMessagingAvailable,loadClientMessages, loadMessageTemplates,loadScheduleMessage,resetMessagesClientData]);

  useEffect(() => {
    setTimeout(scrollToBottom, 0);
  }, [scheduledMessage]);

  useEffect(() => {

    const scrolledDownHandler = () => {
      if (isMessagesLoading) {
        return;
      }
  
      loadClientMessages(clientId, 10, currentPage + 1);
      setCurrentPage(currentPage + 1);
    };

    const handleObserver = (entities: any) => {
      const target = entities[0];
      if (target.isIntersecting) {
        scrolledDownHandler();
      }
    };
    if (currentPage === 0) {
      setTimeout(scrollToBottom, 0);
    } else if (lastLoadedMessageDiv) {
      const targetOffset = lastLoadedMessageDiv.offsetTop - 40;
      if (targetOffset >= 0 && messagesEl.current) {
        messagesEl.current.scrollTop = targetOffset;
      } else {
        return;
      }
    }

    const element = document.getElementsByClassName("instant-message");
    if (element[0]) {
      setLastLoadedMessageDiv(element[0] as HTMLDivElement);
    }

    if (!isMessagesLoading && messages.length >= 10) {
      var options = {
        root: null,
        threshold: 0.5,
      };

      setTimeout(() => {
        if (loadMoreEl.current) {
          observer.current = new IntersectionObserver(handleObserver, options);
          observer.current.observe(loadMoreEl.current);
        }
      }, 500);
    }

    return () => {
      return observer.current?.disconnect();
    };
  }, [
    currentPage,
    clientId,
    loadClientMessages,
    isMessagesLoading,
    lastLoadedMessageDiv,
    messages,
  ]);

  const onChange = (state: EditorState) => {
    setEditorState(state);
    if (!state.getCurrentContent().hasText()) {
      setSelectedTemplate(undefined);
    }
  };

  const sendHandler = (e: any) => {
    setIsEmojiOpened(false);
    let content = editorState.getCurrentContent().getPlainText();
    if (content.length >= 2999) {
      alert("Too long message!");
      return;
    }

    if (content) {
      let messageExtra: MessageExtra = {
        templateData: {
          messageType: selectedTemplate ? 1 : 0,
          lifecycleType: clientTag || null,
        },
      };

      if (selectedTemplate) {
        messageExtra.templateData.messageTemplateId = selectedTemplate.id;
        if (
          selectedTemplate.discountType !== undefined &&
          selectedTemplate.discountValue !== undefined
        ) {
          messageExtra.promotionData = {
            discountType: selectedTemplate.discountType,
            discountValue: selectedTemplate.discountValue,
            discountDurationDays: selectedTemplate.discountDurationDays,
          };
        }
      }

      addMessage(content, messageExtra);
    }
  };

  const promotionMessageAddedHandler = (
    content: string,
    promotionData: MessagePromotionInfo
  ) => {
    setIsPromotionPopupOpened(false);
    let messageExtra: MessageExtra = {
      templateData: {
        messageType: 0,
        lifecycleType: clientTag || null,
      },
      promotionData,
    };
    addMessage(content, messageExtra);
  };

  const addMessage = (content: string, messageExtra: MessageExtra) => {
    content = replaceClientName(content, "");
    if (!content) {
      return;
    }

    sendMessage(clientId, content, messageExtra);
    setEditorState(
      EditorState.push(
        editorState,
        ContentState.createFromText(""),
        "insert-characters"
      )
    );
    setSelectedTemplate(undefined);
    setTimeout(scrollToBottom, 0);
  };

  const openEmojiHandler = () => {
    setIsEmojiOpened(!isEmojiOpened);
  };

  const insertTextToEditor = (text: string) => {
    const currentContent = editorState.getCurrentContent(),
      currentSelection = editorState.getSelection();

    const newContent = Modifier.replaceText(
      currentContent,
      currentSelection,
      text
    );

    const newEditorState = EditorState.push(
      editorState,
      newContent,
      "insert-characters"
    );
    setEditorState(
      EditorState.forceSelection(newEditorState, newContent.getSelectionAfter())
    );
  };
  
  const emojiPickedHandler = (emojiUnicode: any) => {
    insertTextToEditor(emojiUnicode.native);
  };

  const scrollToBottom = () => {
    if (messagesEl.current) {
      messagesEl.current.scrollTop = messagesEl.current.scrollHeight;
    }
  };

  const messageTemplateSelectedHandler = (template: MessageTemplateData) => {
    if (!template) {
      return;
    }

    setSelectedTemplate(template);
    let newText = template.content;
    if (clientNoteName) {
      newText = replaceClientName(template.content, clientNoteName);
    }

    const newEditorState = EditorState.push(
      editorState,
      ContentState.createFromText(newText),
      "insert-characters"
    );
    setEditorState(newEditorState);

    setIsTemplatesPopupOpened(false);
  };

  const cancelTemplateHandler = () => {
    setSelectedTemplate(undefined);
    const newEditorState = EditorState.push(
      editorState,
      ContentState.createFromText(""),
      "insert-characters"
    );
    setEditorState(newEditorState);
  };

  const convertedMessageCloseHandler = () => {
    if (!lastConvertedMessageId) {
      return;
    }

    setLastViewedConvertedMessageId(lastConvertedMessageId);
    saveLastConvertedMessageIdToStorage(clientId, lastConvertedMessageId);
  };

  const hasText = editorState.getCurrentContent().hasText();

  const isSelectedDiscountTemplate =
    !!selectedTemplate && selectedTemplate.discountType !== undefined;

  return (
    <div className="client-card-messages-wrapper">
      <div className="client-card-messages" ref={messagesEl}>
        {isMessagesLoading && <Spinner size={40} />}
        {!!nextPage && !isMessagesLoading && (
          <div className="load-more" ref={loadMoreEl}></div>
        )}
        {!isMessagesLoading &&
          (!messages || messages.length === 0) &&
          !scheduledMessage && <div className="no-items">No messages yet.</div>}
        {messages.map((message, index) => (
          <MessageItem
            key={message.id}
            avatarUrl={expertAvatarUrl}
            isExpertMessage={message.isExpertMessage}
            text={message.content}
            isSheduled={false}
            userName={message.isExpertMessage ? expertName : clientName || ""}
            sentDate={message.createDate}
            readDate={message.readDate}
            hasUsedPromotion={message.hasUsedPromotion}
          />
        ))}

        {!isMessagesLoading && scheduledMessage && (
          <MessageItem
            key={scheduledMessage.id}
            avatarUrl={expertAvatarUrl}
            isExpertMessage={true}
            text={scheduledMessage.content}
            isSheduled={true}
            userName={expertName}
            sentDate={scheduledMessage.sendDate || new Date()}
          />
        )}
        {!isMessagesLoading &&
          !!lastConvertedMessageId &&
          !!messages?.length &&
          lastConvertedMessageId !== lastViewedConvertedMessageId && (
            <div className="converted-label">
              <span className="balloon">$</span>
              Your Client has started a reading after reading your message
              <span
                className="close-btn"
                onClick={convertedMessageCloseHandler}
              ></span>
            </div>
          )}
        {dobOrder >= 0 &&
          dobOrder < 3 &&
          !isMessagesLoading &&
          birthDate &&
          (
            <div className="dob-wrapper">
              <div className="dob-label">
                <BirthdayIcon tooltipText="" />
                <span className="dob-text">
                  {" "}
                  Your client is celebrating a birthday on{" "}
                  {formatDate(birthDate, true)}
                </span>
              </div>
            </div>
          )}
      </div>

      {isMessagingAvailable && !isImpersonated && (
        <div className="client-card-messages-input-area">
          <div className="main-input-area">
            <div
              className={
                "input-wrapper " +
                (isSelectedDiscountTemplate ? "readonly" : "")
              }
            >
              {isSelectedDiscountTemplate && (
                <div
                  className="close-btn"
                  onClick={cancelTemplateHandler}
                ></div>
              )}
              <Editor
                readOnly={isSelectedDiscountTemplate}
                placeholder="Message your client"
                ref={editor}
                editorState={editorState}
                onChange={onChange}
              />

              <div
                className={
                  "emoji-icon-wrapper " +
                  (isEmojiOpened ? "open-emoji" : "close-emoji")
                }
              >
                <EmojiIcon onclick={openEmojiHandler} />
              </div>
              <div className="emoji-picker-wrapper">
                {isEmojiOpened}
                {/* <EmojiPicker
                    onSelect={emojiPickedHandler}
                    isOpen={isEmojiOpened}
                    width="346px"
                  /> */}
                <EmojiPicker
                  onSelect={emojiPickedHandler}
                  isOpen={isEmojiOpened}
                  width="346px"
                />
              </div>
            </div>

            <button
              disabled={!hasText}
              className="client-card-messages__send-button"
              onClick={sendHandler}
            >
              Send
            </button>
          </div>
          {/* temporary removed IM-110 <div className="messages-additional-actions">
            <button
              onClick={() => {
                setIsEmojiOpened(false);
                setIsTemplatesPopupOpened(true);
              }}
              className={
                "white template-button" +
                (isTemplatesPopupOpened ? " active" : "")
              }
            >
              Use a template
            </button>
            <PromotionMessageButton
              isDisabled={clientHasActivePromotion}
              onClick={() => setIsPromotionPopupOpened(true)}
              isActive={isPromotionPopupOpened}
            />
            <div ref={ref}>
              <MessageTemplatePopup
                clientType={clientTag || null}
                onTemplateSelected={messageTemplateSelectedHandler}
                isOpened={isTemplatesPopupOpened}
                onCloseClick={() => setIsTemplatesPopupOpened(false)}
              />
            </div>
            {isPromotionPopupOpened && (
              <MessagePromotionPopup
                onClose={() => setIsPromotionPopupOpened(false)}
                onMessageAdded={promotionMessageAddedHandler}
              />
            )}
          </div> */}
        </div>
      )}
      {!isMessagingAvailable && (
        <div className="client-card-messages-disabled-input-area">
          {!isPotential && <div>New message cannot be sent at this time</div>}
          {isPotential && (
            <div>You may only send one message to a potential client</div>
          )}
        </div>
      )}
    </div>
  );
};

const mapState = (state: AppState) => ({
  messages: state.client.messages,
  clientName: state.client.selectedClient?.clientAlias,
  clientNoteName: state.note.title?.clientName, // TODO: add name to client card details API
  clientTag: state.client.selectedClient?.tag,
  isMessagesLoading: state.client.isMessageLoading,
  isScheduledMessagesLoading: state.client.isScheduleMessageLoading,
  expertAvatarUrl: state.user.userAvatarUrl,
  expertName: state.user.userName,
  nextPage: state.client.nextPage,
  scheduledMessage: state.client.scheduledMessage,
  isMessagingAvailable: state.client.selectedClient?.isMessagingAvailable,
  clientHasActivePromotion: state.client.selectedClient?.hasActivePromotion,
  lastConvertedMessageId: state.client.selectedClient?.lastConvertedMessageId,
  dobOrder:
    selectedClientDobOrderSelector(state) === 0
      ? 0
      : selectedClientDobOrderSelector(state) || 10,
  birthDate: state.note.title?.birthDate,
  isPotential: state.client.selectedClient?.isPotential,
  isImpersonated: isImpersonatedSelector(state)
});

const connector = connect(mapState, {
  resetMessagesClientData,
  loadClientMessages,
  sendMessage,
  loadScheduleMessage,
  scheduleMessageLoaded,
  loadMessageTemplates,
});

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Messages);
