import { ClientReadingData } from "../../types/clientTypes";
import api from "./api";
import axios, { AxiosError, HttpStatusCode } from "axios";
import { CANCEL } from "redux-saga";
import { Response } from "./api";
import { MyActivityApiResponse } from "./models/MyActivityApiResponse";
import { getSessionType } from "../../helpers/clientListHelper";
import { GenderType } from "../../types/clientListTypes";

export type GetLastReadingsResponse = {
  items: ClientReadingData[];
  nextPage: number | undefined;
  //kasambaViewAllQueryString: string; TODO: remove it
};

export type ClientCardDetailsResponse = {
  clientAlias: string;
  sessionCount: number;
  lastSessionEndTime: string | null;
  isFavoriteClient: boolean;
  isMessagingAvailable: boolean;
  tag: number | null;
  hasActivePromotion: boolean;
  tier: number | null;
  isBlocked: boolean;
  dobOrder: number;
  lastConvertedMessageId?: number;
  isPotential: boolean;
  avatarUrl: string | null;
  totalPaid: number;
  gender: GenderType;
  dob: Date | null;
  firstSession: Date | null;
  givenDob: Date | null;
};

export enum BaseResponseStatusModel {
  Success = 0,
  Failed = 1,
  InvalidModel = 2,
}
export interface ChangeClientBlockStatusResponseVm {
  clientBlocked: boolean;
  status: BaseResponseStatusModel;
  errors: { [key: string]: string[] } | undefined;
}
export interface ChangeClientBlockStatusRequestVm {
  clientId: number;
  blockUser: boolean;
  blockSource: string;
  blockReason: string;
  blockReasonElaboration: string;
}

interface ApiClientCardDetail {
  buyer_name: string;
  buyer_nickname: string;
  given_name: string;
  dob_order: number;
  id: number;
  favorite: boolean;
  blocked: boolean;
  potential: boolean;
  orders_count: number;
  last_order_submitted_at: Date;
  given_dob_order: any; //???
  messaging_status: string;
  buyer_avatar_url: string | null;
  total_paid: string;
  gender: GenderType;
  dob: string | null;
  given_dob: string | null;
  first_session: string | null;
  acted_at: string | null;
}

export const getClientCardDetails = (
  clientId: number
): Promise<ClientCardDetailsResponse> => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  let request: any = api
    .get<ApiClientCardDetail>(`/my/clients/${clientId}`, {
      cancelToken: source.token,
    })
    .then((response) => {
      return {
        clientAlias: response.data?.buyer_nickname
          ? response.data?.buyer_nickname
          : response.data?.buyer_name,
        sessionCount: response.data?.orders_count,
        lastSessionEndTime: response.data?.last_order_submitted_at,
        isFavoriteClient: response.data?.favorite,
        isMessagingAvailable: response.data?.messaging_status === "allowed",
        isBlocked: response.data?.blocked,
        dobOrder: response.data?.dob_order,
        isPotential: response.data?.potential,
        avatarUrl: response.data?.buyer_avatar_url,
        totalPaid: parseFloat(response.data?.total_paid),
        gender: response.data?.gender as GenderType,
        dob: (response.data?.dob && new Date(response.data.dob)) || null,
        givenDob:
          (response.data?.given_dob && new Date(response.data.given_dob)) ||
          null,
        firstSession: response.data?.first_session
          ? new Date(response.data?.first_session)
          : null,
        lastConvertedMessageId: null, // API does not implemented it
        hasActivePromotion: false, // API does not implemented it
        tag: 0, // API does not implemented it
        tier: 0, // API does not implemented it
      };
    });

  request[CANCEL] = () => source.cancel(); // cancel request if saga is cancelled (takeLatest for example)
  return request;
};

export function getClientLifecycle(clientId: number): Promise<number | null> {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  let request: any = api
    .get<Response<number | null>>(
      `/api/experts/me/clients/${clientId}/lifecycle`,
      { cancelToken: source.token }
    )
    .then((response) => response.data.result);

  request[CANCEL] = () => source.cancel(); // cancel request if saga is cancelled (takeLatest for example)
  return request;
}

const PAGE_SIZE = 30; // should be the same as PAGE_SIZE in the LastReadingsLoader

export function getLastReadings(
  clientId: number,
  contToken: number = 0
): Promise<GetLastReadingsResponse> {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  let request: any = api
    .get<MyActivityApiResponse>(
      `/my/activity?buyer_id=${clientId}&show_only=orders&page=${contToken}&items_per_page=${PAGE_SIZE}`,

      { cancelToken: source.token }
    )
    .then((response): GetLastReadingsResponse => {
      return {
        items: response.data?.activity_items?.map((t) => ({
          clientId: response.data.buyer.id,
          sessionId: t.data.id,
          sessionType: getSessionType(t.data.order_type || ""), // "test" "rush" "video" "voice call" "chat" "regular" (Kasamba will have "voice call" and "chat"  as I know)
          sessionStartTime: t.data.submitted_at
            ? new Date(t.data.submitted_at)
            : null,
          durationInSecond: t.data.charge_duration,
          finalFee: t.data.earned === undefined ? null : t.data.earned, //number | null;
          rating: t.data.feedback_vote,
          ratingComment: t.data.feedback_comment,
          kasambaQueryString: "",
        })),
        nextPage:
          contToken === response.data?.pagination_params?.page
            ? undefined
            : response.data?.pagination_params?.page,
      };
    }).catch((e) => {
      if (e instanceof AxiosError && e.response?.status === HttpStatusCode.NotFound) {
        return {
          items: [],
          nextPage: undefined
        }
      }
      throw e;
    });
  request[CANCEL] = () => source.cancel();
  return request;
}

export function changeClientBlockStatus(
  clientId: number,
  block: boolean
): Promise<ChangeClientBlockStatusResponseVm> {
  if (block) {
    return api
      .post("/my/advisor/blocked_interactions", {
        user_id: clientId,
        platform: "WEM",
        reason: "request from expert",
      })
      .then((response) => {
        return {
          clientBlocked: true,
          status: BaseResponseStatusModel.Success,
          errors: undefined,
        };
      });
  } else {
    return api
      .delete(`/my/advisor/blocked_interactions/${clientId}`)
      .then((response) => {
        return {
          clientBlocked: true,
          status: BaseResponseStatusModel.Success,
          errors: undefined,
        };
      });
  }
}
