import { useEffect, useState } from "react";
import { AppState } from "../../store";
import { isSavingClientDetailsSelector } from "../../selectors/noteSelectors";
import "./ClientCard.scss";
import { setFavorite } from "../../slices/clientListSlice";
import {
  clientLoaded,
  blockClient,
  resetNoteTitleFocusedField,
} from "../../slices/clientSlice";
import {
  loadNote,
  updateNote,
  updateClientDetails,
} from "../../slices/noteSlice";
import { connect, ConnectedProps } from "react-redux";
import { RoutesM } from "../../router";
import {
  convertToMonthAndYear,
  kasambaFormatDate,
} from "../../helpers/dateFormatHelper";
import FavoriteIcon from "../atoms/FavoriteIcon";
import Spinner from "../atoms/Spinner";
import NoteEditor from "../atoms/NoteEditor";
import { useRef } from "react";
import LastReadingsLoader from "../atoms/LastReadingsLoader";
import NoteTitleEditor from "../atoms/NoteTitleEditor";
import Messages from "../molecules/Messages";
import { ClientCardTab, PopupState } from "../../types/clientTypes";
import { showModal } from "../../slices/modalSlice";
import { ModalType } from "../../types/modalTypes";
import { advisorBlockClientTierSelector } from "../../selectors/appSettingsSelector";
import { Tooltip } from "react-tooltip";
import ClientCardTranscript from "../molecules/ClientCardTranscript";
import { transcriptStateSelector } from "../../selectors/clientSelectors";
import { textManager } from "../../config/textManager";
import { isImpersonatedSelector } from "../../slices/userSlice";
import { Avatar } from "../atoms/Avatar";
import { DOB } from "../atoms/DOB";

const ClientCard = ({
  selectedClient,
  currentPage,
  clientLoaded,
  setFavorite,
  loadNote,
  isNoteLoading,
  note,
  noteTitle,
  noteLastUpdated,
  updateNote,
  updateClientDetails,
  isFavorite,
  selectedTab,
  isClientLoading,
  selectedClientId,
  blockClient,
  showModal,
  advisorBlockClientTier,
  setFocusOnField,
  resetNoteTitleFocusedField,
  isSavingClientDetails,
  transcriptState,
  isImpersonated,
}: PropsFromRedux) => {
  const [currentTab, setCurrentTab] = useState<ClientCardTab>();

  useEffect(() => {
    if (selectedClientId) {
      let tab = selectedTab || ClientCardTab.Notes;
      if (selectedClient?.isPotential && tab === ClientCardTab.LastReading) {
        tab = ClientCardTab.Notes;
      }
      setCurrentTab(tab);
      if (tab === ClientCardTab.Notes) {
        loadNote(selectedClientId);
      }
    }
  }, [selectedClientId, loadNote, selectedTab, selectedClient?.isPotential]);

  const tabClickHandler = (tab: ClientCardTab) => {
    if (selectedClient && setFocusOnField) {
      resetNoteTitleFocusedField();
    }

    if (tab === currentTab || !selectedClient) {
      return;
    }
    setCurrentTab(tab);
    if (tab === ClientCardTab.Notes) {
      loadNote(selectedClient.clientId);
      return;
    }

    if (tab === ClientCardTab.LastReading) {
      return;
    }
  };

  const clientCardRef = useRef<HTMLDivElement>(null);
  const handleNoteEditorOpened = () => {
    setTimeout(() => scrollToBottom(), 0);
  };

  const scrollToBottom = () => {
    if (clientCardRef.current) {
      clientCardRef.current.scrollTop = clientCardRef.current.scrollHeight;
    }
  };

  const blockClientConfirm = () => {
    if (selectedClient) {
      if (selectedClient.isBlocked) {
        blockClient(false);
      } else {
        const modalType =
          (selectedClient.tier || 0) > advisorBlockClientTier
            ? ModalType.BlockClientHighValued
            : ModalType.BlockClientConfirm;
        showModal(modalType);
      }
    }
  };

  const dob = selectedClient?.dob || selectedClient?.givenDob;

  return (
    <div className="client-card-container">
      <div
        className={
          "client-card" +
          (selectedClientId && currentPage === RoutesM.Clients ? " open" : "") +
          (selectedClient?.isBlocked ? " blocked" : "")
        }
      >
        <div className="client-card-overlay" />
        {isClientLoading && (
          <div className="client-card-loader">
            <Spinner size={60} />
          </div>
        )}
        <div
          className={
            "transcript" + (transcriptState === PopupState.Open ? " open" : "")
          }
        >
          <ClientCardTranscript />
        </div>

        {selectedClient && !isClientLoading && (
          <div className="client-card-content">
            <Tooltip id="client-card-tooltip" place="left" />
            <Tooltip
              id="client-card-tooltip-top"
              place="top"
              className="tool-tip-up"
            />
            <div className="client-card-controls">
              {!selectedClient.isPotential && (
                <>
                  {/* removed by https://ingenio.atlassian.net/browse/IM-151
                   <BlockClientIcon
                    isBlocked={selectedClient.isBlocked}
                    onclick={() => blockClientConfirm()}
                    isProcessing={selectedClient.isBlockProcessing}
                  /> */}
                  <FavoriteIcon
                    isFavoriteClient={isFavorite || false}
                    isImpersonated={isImpersonated}
                    onclick={() => {
                      setFavorite(
                        selectedClient.clientId,
                        !selectedClient.isFavoriteClient,
                        selectedClient.clientAlias,
                        "client card"
                      );
                    }}
                  />
                </>
              )}

              <div
                className="close"
                onClick={() => {
                  clientLoaded(null);
                  if (setFocusOnField) {
                    resetNoteTitleFocusedField();
                  }
                }}
              ></div>
            </div>
            <div className="client-card-body-wrapper">
              <div className="client-card-body" ref={clientCardRef}>
                <div className="client-card-title">
                  <Avatar url={selectedClient.avatarUrl} gender={selectedClient.gender} />
                  <div className="client-summary">
                    <h1 className="client-alias">
                      {selectedClient.clientAlias}
                    </h1>
                    <DOB dob={dob} />
                  </div>
                </div>
                <div className="client-card-info">
                  <span>ID: {selectedClient.clientId}</span>
                  {!selectedClient.isPotential && (
                    <>
                      <span>
                        # {textManager.clientCard.sessions}:{" "}
                        {selectedClient.sessionCount}
                      </span>
                      {selectedClient.lastSessionEndTime && (
                        <span>
                          {textManager.readings.lastReading}
                          {kasambaFormatDate(selectedClient.lastSessionEndTime)}
                        </span>
                      )}
                      <span>
                        <span className="paid">Paid: </span>
                        <span className="amount">
                          ${selectedClient.totalPaid.toFixed(2)}
                        </span>
                        <i
                          data-tooltip-content={`Total spend $${
                            selectedClient.totalPaid.toFixed(2)
                          } with you since: ${convertToMonthAndYear(
                            selectedClient.firstSession
                          )}`}
                          data-tooltip-id="client-card-tooltip-top"
                          className="question-mark"
                        ></i>
                      </span>
                    </>
                  )}
                </div>

                <div className="client-card-tabs-nav">
                  <div
                    className={
                      currentTab === ClientCardTab.Notes ? "active" : ""
                    }
                    onClick={() => tabClickHandler(ClientCardTab.Notes)}
                  >
                    Notes
                  </div>

                  {!selectedClient.isPotential && (
                    <div
                      className={
                        currentTab === ClientCardTab.LastReading ? "active" : ""
                      }
                      onClick={() => tabClickHandler(ClientCardTab.LastReading)}
                    >
                      {textManager.readings.clientReading}
                    </div>
                  )}
                  <div
                    className={
                      currentTab === ClientCardTab.Messages ? "active" : ""
                    }
                    onClick={() => tabClickHandler(ClientCardTab.Messages)}
                  >
                    Messages
                  </div>
                </div>
                <div className="client-card-tabs-content">
                  {isNoteLoading && currentTab === ClientCardTab.Notes && (
                    <Spinner size={40} />
                  )}
                  {!isNoteLoading && currentTab === ClientCardTab.Notes && (
                    <div className="client-card-note">
                      {!selectedClient.isPotential && (
                        <NoteTitleEditor
                          clientName={noteTitle?.clientName}
                          isSaving={isSavingClientDetails}
                          birthDate={noteTitle?.birthDate}
                          setFocusOnField={setFocusOnField}
                          isImpersonated={isImpersonated}
                          onClientDetailsChange={(
                            clientName: string,
                            clientDob: Date | null
                          ) => {
                            updateClientDetails(
                              selectedClient.clientId,
                              selectedClient.clientAlias,
                              clientName,
                              noteTitle?.clientName || "",
                              clientDob,
                              noteTitle?.birthDate || null
                            );
                          }}
                        />
                      )}
                      <NoteEditor
                        text={note}
                        onEditorOpened={handleNoteEditorOpened}
                        onTextChangedInTheEnd={handleNoteEditorOpened}
                        lastUpdated={noteLastUpdated}
                        isImpersonated={isImpersonated}
                        onNoteChange={(
                          newNote: string,
                          plainText: string,
                          isAdded
                        ) =>
                          updateNote(
                            selectedClient.clientId,
                            selectedClient.clientAlias,
                            newNote,
                            plainText,
                            isAdded
                          )
                        }
                      />
                    </div>
                  )}

                  {selectedClient &&
                    currentTab === ClientCardTab.LastReading && (
                      <div className="client-card-last-reading">
                        <div className="mute-text">
                          {textManager.readings.allReading}
                        </div>
                        <LastReadingsLoader
                          clientId={selectedClient.clientId}
                        />
                      </div>
                    )}

                  {selectedClient && currentTab === ClientCardTab.Messages && (
                    <Messages clientId={selectedClient.clientId} />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapState = (state: AppState) => ({
  isClientLoading: state.client.isClientLoading,
  selectedClient: state.client.selectedClient,
  selectedClientId: state.client.selectedClientId,
  selectedTab: state.client.selectedCardTab,
  setFocusOnField: state.client.setFocusOnField,
  currentPage: state.router.location?.pathname,
  isNoteLoading: state.note.isLoading,
  noteTitle: state.note.title,
  isSavingClientDetails: isSavingClientDetailsSelector(state),
  note: state.note.text,
  noteLastUpdated: state.note.lastUpdated,
  isFavorite: state.client.selectedClient?.isFavoriteClient,
  advisorBlockClientTier: advisorBlockClientTierSelector(state),
  transcriptState: transcriptStateSelector(state),
  isImpersonated: isImpersonatedSelector(state),
});

const connector = connect(mapState, {
  clientLoaded,
  setFavorite,
  loadNote,
  updateClientDetails,
  updateNote,
  blockClient,
  showModal,
  resetNoteTitleFocusedField,
});

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ClientCard);
