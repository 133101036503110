import { CreateAttachmentResponse } from "../services/api/models/my-orders";
import Compressor from "compressorjs";

export function replaceClientName(text: string, replaceWith: string) {
  let regex = /\[client name\]/gi;
  if (!replaceWith) {
    regex = /\s?\[client name\]\s?/gi;
  }
  return text.replace(regex, replaceWith);
}

export function isValidDate(dt: Date) {
  try {
    return dt.getTime() === dt.getTime();
  } catch (e) {
    return false;
  }
}

export function toCurrencyFormat(value: number) {
  return value.toLocaleString("en-US", { style: "currency", currency: "USD" });
}

export async function compressImage(
  file: File,
  options: CreateAttachmentResponse
): Promise<File> {
  const srcBitmap = await createImageBitmap(file);
  const isBitmapBigger =
    srcBitmap.width > options.resize_longest_side ||
    srcBitmap.height > options.resize_longest_side;

  if (!isBitmapBigger) return file;

  return await scaledBitmap(file, srcBitmap, options);
}

function scaledBitmap(
  file: File,
  bitmap: ImageBitmap,
  options: CreateAttachmentResponse
): Promise<File> {
  let scale, newWidth: number, newHeight: number;

  if (bitmap.width > options.resize_longest_side) {
    scale = options.resize_longest_side / bitmap.width;
    newWidth = options.resize_longest_side;
    newHeight = Math.floor(bitmap.height * scale);
  } else {
    scale = options.resize_longest_side / bitmap.height;
    newWidth = Math.floor(bitmap.width * scale);
    newHeight = options.resize_longest_side;
  }

  return new Promise<File>((resolve, reject) => {
    new Compressor(file, {
      width: newWidth,
      height: newHeight,
      quality: options.reduce_quality / 100,
      success: (result: File) => {
        resolve(result);
      },
      error: (error: Error) => {
        reject(error);
      },
    });
  });
}

export function getEnumKeyByEnumValue<T extends {[index: string]: string|number}>(myEnum: T, enumValue: string|number): keyof T | null {
  const keys = Object.keys(myEnum).filter(key => myEnum[key] === enumValue);
  return keys.length > 0 ? keys[0] : null;
}

