import "./SessionNotes.css";
import {
  isSavingClientDetailsSelector
} from "../../selectors/noteSelectors";
import NoteEditor from "../atoms/NoteEditor";
import { AppState } from "../../store";
import { connect, ConnectedProps } from "react-redux";
import {
  updateNote,
  updateClientDetails,
} from "../../slices/noteSlice";
import NoteTitleEditor from "../atoms/NoteTitleEditor";
import { clientSelector } from "../../selectors/sessionSelectors";

const SessionNotes = ({
  noteTitle,
  clientId,
  note,
  noteLastUpdated,
  updateNote,
  onNoteEditorOpened,
  isSavingClientDetails: isSaving,
  onTextChangedInTheEnd,
  updateClientDetails,
  isNoteLoading,
  client,
}: PropsFromRedux & SessionNotesProps) => (
  <div className="session-notes">
    <div className="title">NOTES</div>
    {!isNoteLoading && (
      <NoteTitleEditor
        clientName={noteTitle?.clientName}
        birthDate={noteTitle?.birthDate}
        setFocusOnField={null}
        onClientDetailsChange={(clientName: string, clientDob: Date | null) => {
          updateClientDetails(
            clientId,
            client?.name || "",
            clientName,
            noteTitle?.clientName || "",
            clientDob,
            noteTitle?.birthDate || null
          );
        }}
        isSaving={isSaving}
      />
    )}

    {!isNoteLoading && (
      <NoteEditor
        text={note}
        onEditorOpened={onNoteEditorOpened}
        onTextChangedInTheEnd={onTextChangedInTheEnd}
        lastUpdated={noteLastUpdated}
        onNoteChange={(newNote: string, plainText:string, isAdded) =>
            updateNote(clientId, client?.name|| "", newNote,plainText, isAdded)
        }
      />
    )}
  </div>
);

interface SessionNotesProps {
  onNoteEditorOpened?: () => void;
  onTextChangedInTheEnd?: () => void;
}

const mapState = (state: AppState) => ({
  noteTitle: state.note.title,
  note: state.note.text,
  noteLastUpdated: state.note.lastUpdated,
  clientId: Number(state.session.client?.id),
  isSavingClientDetails: isSavingClientDetailsSelector(state),
  isNoteLoading: state.note.isLoading,
  client: clientSelector(state),
});

const connector = connect(mapState, {
  updateNote,
  updateClientDetails
});
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SessionNotes);
