export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const ENVIRONMENT = process.env.REACT_APP_ENV;

export const LIFE_TIME_OF_TYPING_INDICATOR = 5000;
export const DELAY_BETWEEN_ONBOARDING_SLIDES_AND_TOOLTIPS = 2000;
export const APP_VERSION = process.env.REACT_APP_VERSION as string;
export const KASAMBA_VIEW_READING_LINK =
  "https://www.kasamba.com/history/view-transcript.aspx";
export const KASAMBA_VIEW_ALL_READING_LINK =
  "https://www.kasamba.com/billing/billing-information/sessions-history.aspx";
export const DELAY_BETWEEN_NEW_VERSION_CHECKS = 20 * (1000 * 60); // 20 minutes
export const DEFAULT_VOLUME = 100;
export const DEFAULT_FONT_SIZE = 16;
export const DEFAULT_RINGING = 1;
export const KASAMBA_ATTACH_FILES_LINK =
  "http://www.kasamba.com/Application/CSUrlReferer.aspx?UrlId=5&sessionID={sessionId}";
export const DELAY_BEFOR_START_SEARCH = 1000; // ms
export const START_SEARCH_MIN_PATTERN_LENGTH = 1;
export const CLIENT_LIST_DEFAULT_PRESET = 1; // ClientListFilterPresetEnum.RecentlyContacted;
export const RETRY_MAX_TRIES = 3;
export const RETRY_DELAY = 10000;
export const RETRY_SHORT_DELAY = 5000;
export const IS_EXPERT_ACTIVE_CHECK_INTERVAL = 30 * 60 * 1000; // 30 minutes
export const NUMBER_OF_ONBOARDING_TOOLTIPS = 6;
export const NUMBER_OF_TUTORIAL_SLIDES = 5;
export const DISPLAY_TIME_OF_SAVED = 2000; // 2 sec

export const MAXIMUM_SIZE_IN_BYTES_OF_THE_UPLOADED_FILE = 5 * 1024 * 1024;
export const MAXIMUM_NUMBER_OF_FILES_IN_ONE_MESSAGE = 1;

export const SITEID = 10;

export const CURRENT_SITE_ID = process.env.REACT_APP_CURRENT_SITE_ID;

export const SUPPORT_SOCIAL_LOGIN = Boolean(
  process.env.REACT_APP_SUPPORT_SOCIAL_LOGIN
);

export const TERMS_AND_CONDITIONS_LINK = process.env.REACT_APP_TERMS_AND_CONDITIONS_LINK;
export const PRIVACY_LINK = process.env.REACT_APP_PRIVACY_LINK;
export const HELP_LINK = process.env.REACT_APP_HELP_LINK;
export const HOTJAR_SITE_ID = parseInt(process.env.REACT_APP_HOTJAR_SITE_ID || '0') || 0;
export const HOTJAR_ENABLED = process.env.REACT_APP_HOTJAR_ENABLED || false;
export const HOTJAR_SNIPPET_VERSION = parseInt(process.env.REACT_APP_HOTJAR_SNIPPET_VERSION || '0') || 0;

export const LOGS_BATCH_KEY = "logs_batch";
export const LOGS_SAVING_INTERVAL = 60 * 1000;
export const LOGS_MAX_BATCH_SIZE = 60;

export const CHAT_RING_DURATION = 60 * 1000; // 60 seconds

export const disableNotesEditing = Boolean(process.env.REACT_APP_DISABLE_NOTES_EDITING);