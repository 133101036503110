import { createSlice } from "@reduxjs/toolkit";
import { ClientListData, ClientListState } from "../types/clientListTypes";
import { CLIENT_LIST_DEFAULT_PRESET } from "../config";
import { ClientListFilterPresetEnum, ClientListSortFieldEnum, ClientListTagCountResponse, SortOrder, TagFilterEnum } from "../services/api/clientListApi";
import { updatedClientDetails } from "./noteSlice";
import { NewJobMessageNotification } from "../services/api/models/NewMessageNotification";

const initialState: ClientListState = {
  currentPage: 1,
  pageData: [],
  rowsPerPage: 10,
  count: 0,
  isLoadingPageData: false,
  isLoadingCount: false,
  filterPreset: CLIENT_LIST_DEFAULT_PRESET,
  sortField: ClientListSortFieldEnum.DOBWithLastSessionEndTime,
  sortingOrder: SortOrder.Desc,
  searchText: null,
  tags: {
    selected: null,
    amounts: [0, 0, 0, 0, 0],
    isLoading: false,
  },
  requestedPreset: null,
};

const clientListSlice = createSlice({
  name: 'clientList',
  initialState,
  reducers: (create) => ({
    openClientList() { },
    pageLoading(state) {
      state.isLoadingPageData = true;
    },
    pageLoaded: create.preparedReducer(
      (data: Array<ClientListData>) => ({ payload: { data } }),
      (state, action) => {
        state.pageData = action.payload.data;
        state.isLoadingPageData = false;
      }
    ),
    countLoaded: create.preparedReducer(
      (count: number) => ({ payload: { count } }),
      (state, action) => {
        state.count = action.payload.count;
        state.isLoadingCount = false;
      }
    ),
    countLoading(state) {
      state.isLoadingCount = true;
    },
    resetClientList: () => initialState,
    gotoNextPage() { },
    gotoPrevPage() { },
    gotoPage: create.preparedReducer(
      (pageNumber: number) => ({ payload: { pageNumber } }),
      () => { }
    ),
    setPage: create.preparedReducer(
      (pageNumber: number) => ({ payload: { pageNumber } }),
      (state, action) => {
        state.currentPage = action.payload.pageNumber;
      },
    ),
    setRowsPerPage: create.preparedReducer(
      (rowsPerPage: number) => ({ payload: { rowsPerPage } }),
      (state, action) => {
        state.rowsPerPage = action.payload.rowsPerPage;
      },
    ),
    rowsPerPageChanged: create.preparedReducer(
      (rowsPerPage: number) => ({ payload: { rowsPerPage } }),
      () => { },
    ),
    markAsScheduled: create.preparedReducer(
      (clientId: number, created: string | null, scheduledDate: string | null) =>
        ({ payload: { clientId, created, scheduledDate } }),
      (state, action) => {
        if (state.pageData) {
          state.pageData.forEach(client => {
            if (client.clientId == action.payload.clientId) {
              client.isScheduledMessageEnabled = false;
              client.lastScheduledMessageCreated = action.payload.created;
              client.lastScheduledMessageDate = action.payload.scheduledDate;
            }
          });
        }
      },
    ),
    setFilterPreset: create.preparedReducer(
      (filterPreset: ClientListFilterPresetEnum) => ({ payload: { filterPreset } }),
      (state, action) => {
        state.filterPreset = action.payload.filterPreset;
        state.tags.selected = null;
      },
    ),
    filterPresetWasChanged: create.preparedReducer(
      (filterPreset: ClientListFilterPresetEnum) => ({ payload: { filterPreset } }),
      () => { },
    ),
    setFavorite: create.preparedReducer(
      (clientId: number, isFavorite: boolean, clientName: string, location: string) =>
        ({ payload: { clientId, isFavorite, clientName, location } }),
      (state, action) => {
        if (state.pageData) {
          const item = state.pageData.find(x => x.clientId == action.payload.clientId);
          if (item) {
            item.isFavoriteClient = action.payload.isFavorite;
          }
        }
      },
    ),
    setSorting: create.preparedReducer(
      (sortField: ClientListSortFieldEnum, sortingOrder: string) => ({ payload: { sortField, sortingOrder } }),
      (state, action) => {
        state.sortField = action.payload.sortField;
        state.sortingOrder = action.payload.sortingOrder;
      },
    ),
    clickColumnHeader: create.preparedReducer(
      (sortField: ClientListSortFieldEnum) => ({ payload: { sortField } }),
      () => { },
    ),
    searchTextChanged: create.preparedReducer(
      (text: string | null) => ({ payload: { text } }),
      () => { },
    ),
    searchTextClear(state) {
      state.searchText = null;
    },
    searchTextStore: create.preparedReducer(
      (searchText: string | null) => ({ payload: { text: searchText } }),
      (state, action) => {
        state.searchText = action.payload.text;
      },
    ),
    searchTextProcess: create.preparedReducer(
      (searchText: string | null) => ({ payload: { text: searchText } }),
      () => { },
    ),
    setUnreadMessages: create.preparedReducer(
      (clientId: number, value: boolean) => ({ payload: { clientId, value } }),
      (state, action) => {
        if (state.pageData) {
          const item = state.pageData.find(x => x.clientId === action.payload.clientId);
          if (item) {
            item.hasUnreadMessages = action.payload.value;
            if (action.payload.value) {
              item.lastInstantMessageXDayAgo = 0;
            }
          }
        }
      },
    ),
    tagCounting(state) {
      state.tags.amounts = [0, 0, 0, 0];
      state.tags.isLoading = true;
    },
    tagCounted: create.preparedReducer(
      (data: ClientListTagCountResponse | null) => ({ payload: { amounts: data } }),
      (state, action) => {
        let amounts = [0, 0, 0, 0];
        if (action.payload.amounts) {
          action.payload.amounts.forEach((value) => {
            amounts[value.tag] = value.amount;
          });
        }
        state.tags.amounts = amounts;
        state.tags.isLoading = false;
      },
    ),
    tagSelected: create.preparedReducer(
      (filterTag: TagFilterEnum) => ({ payload: { filterTag } }),
      () => { },
    ),
    tagSelectedSave: create.preparedReducer(
      (filterTag: TagFilterEnum) => ({ payload: { filterTag } }),
      (state, action) => {
        if (state.tags.selected === action.payload.filterTag) {
          state.tags.selected = null;
        } else {
          state.tags.selected = action.payload.filterTag;
        }
      },
    ),
    refreshPage() { },
    requestViewPendingList: create.preparedReducer(
      (preset: ClientListFilterPresetEnum) => ({ payload: { preset } }),
      (state, action) => {
        state.requestedPreset = action.payload.preset;
      }
    ),
    newJobMessage: create.preparedReducer(
      (value: NewJobMessageNotification) => ({ payload: value }),
      () => { },
    )
  }),
  extraReducers: (builder) => {
    builder
      .addCase(updatedClientDetails, (state, action) => {
        if (!state.pageData) {
          return;
        }
        const item = state.pageData.find(
          (i) => i.clientId === action.payload.clientId
        );
        if (item) {
          item.clientNameFromNote = action.payload.clientName;
          item.givenDob = action.payload.clientDob;
        }
      });
  }
});

export const {
  openClientList, pageLoading, pageLoaded, countLoaded, countLoading, resetClientList, gotoNextPage,
  gotoPrevPage, gotoPage, setPage, setRowsPerPage, rowsPerPageChanged, markAsScheduled, setFilterPreset,
  filterPresetWasChanged, setFavorite, setSorting, clickColumnHeader, searchTextChanged, searchTextClear,
  searchTextStore, searchTextProcess, setUnreadMessages, tagCounting, tagCounted, tagSelected, tagSelectedSave, 
  refreshPage, requestViewPendingList,newJobMessage
} = clientListSlice.actions;

export const clientListReducer = clientListSlice.reducer;