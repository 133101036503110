import "./NoteTitleEditor.scss";
import { useState, useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { formatDate, months } from "../../helpers/dateFormatHelper";
import { EditTitleFields } from "../../types/clientTypes";
import { SaveConfirmButton } from "./buttons/SaveConfirmButton";
import { Controller } from "react-hook-form";
import Select from "react-select";

const placeholder = "Title (example: name, dob)";

export interface IClientDetailsFormInput {
  clientName: string | undefined | null;
  day: string;
  month: { value: string; label: string } | null;
  year: string;
}

interface NoteTitleEditorProps {
  clientName: string | undefined;
  isSaving: boolean;
  birthDate: Date | null | undefined;
  setFocusOnField: EditTitleFields | null;
  onClientDetailsChange: (clientName: string, clientDob: Date | null) => void;
  isImpersonated?: boolean;
}

const NoteTitleEditor = (props: NoteTitleEditorProps) => {
  const clientDetailsForm = useForm<IClientDetailsFormInput>();
  const values = useWatch<IClientDetailsFormInput>({
    control: clientDetailsForm.control,
  });

  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    clientDetailsForm.reset({
      clientName: props.clientName,
      day: props.birthDate?.getDate().toString() || "",
      month: props.birthDate ? months[props.birthDate.getMonth()] : null,
      year: yearValue(props.birthDate?.getFullYear()),
    });
  }, [props.clientName, props.birthDate]);

  const handleEdit = () => {
    if (props.isImpersonated) {
      return;
    }
    setIsEditMode(true);
  };

  function onSubmit(data: IClientDetailsFormInput) {
    props.onClientDetailsChange(
      data.clientName || "",
      data.day && data.month && data.year
        ? new Date(
            parseInt(data.year, 10),
            parseInt(data.month.value, 10) - 1,
            parseInt(data.day, 10)
          )
        : null
    );
  }

  function validateDay(day: string, month: any, year: any): boolean {
    if (day) {
      const dayNumber = parseInt(day, 10);

      if (dayNumber > 31 || dayNumber < 1) {
        return false;
      }

      if (month && month.value) {
        const yearNumber = 2020; // we need a leap year
        const monthNumber = parseInt(month.value, 10) - 1;

        const dt = new Date(yearNumber, monthNumber, dayNumber);

        return (
          dt.getFullYear() === yearNumber &&
          dt.getDate() === dayNumber &&
          dt.getMonth() === monthNumber
        );
      }
    }
    return true;
  }
  function yearValue(value: number | null | undefined): string {
    if (value) {
      return value.toString();
    }
    if ("" + value === "0") {
      return "0";
    }
    return "";
  }

  const buildTitle = (
    clientName: string | undefined,
    birthDate: Date | null | undefined
  ): string => {
    let title = "";

    if (clientName) {
      title += `${clientName}, `;
    }

    if (birthDate) {
      title += `${formatDate(birthDate)}`;
    }

    return title;
  };

  const isFormReady =
    clientDetailsForm.formState.isDirty &&
    ((values.clientName &&
      !(!!values.day || !!values.month?.value || !!values.year)) ||
      (!!values.day && !!values.month?.value && !!values.year));

  return (
    <div className="note-title-editor">
      {!isEditMode && (
        <div className="read-title">
          <div onClick={handleEdit}>
            {buildTitle(props.clientName, props.birthDate) || placeholder}
          </div>
        </div>
      )}

      {isEditMode && (
        <>
          <form
            onSubmit={clientDetailsForm.handleSubmit(onSubmit)}
            className="title-form"
          >
            <div className="row">
              <label className="label">Client name</label>

              <input
                type="text"
                {...clientDetailsForm.register("clientName", {
                  maxLength: 40,
                })}
                name="clientName"
                placeholder="Name between 1-40 characters"
                className={`client-name-edit ${
                  clientDetailsForm.formState.errors.clientName ? "error" : ""
                }`}
                aria-invalid={
                  clientDetailsForm.formState.errors.clientName
                    ? "true"
                    : "false"
                }
                autoFocus={
                  props.setFocusOnField === EditTitleFields.Name ||
                  !props.setFocusOnField
                }
              />

              {clientDetailsForm.formState.errors.clientName?.type ===
                "maxLength" && (
                <div className="error-message">Max length exceeded</div>
              )}
            </div>

            <div className="row">
              <label className="text">Client DOB</label>

              <Controller
                name="month"
                control={clientDetailsForm.control}
                render={({ field }) => (
                  <Select
                    className="selectMonth"
                    classNamePrefix="select-month"
                    {...field}
                    placeholder="Month"
                    options={months}
                    autoFocus={props.setFocusOnField === EditTitleFields.DOB}
                  />
                )}
                rules={{ required: false }}
              />

              <input
                {...clientDetailsForm.register("day", {
                  pattern: /^[0-9]{0,2}$/,
                  max: 31,
                  min: 1,
                  validate: (value) =>
                    validateDay(
                      String(value),
                      clientDetailsForm.getValues("month"),
                      clientDetailsForm.getValues("year")
                    ),
                })}
                type="text"
                placeholder="Day"
                className="note-title-editor-input day"
                aria-invalid={
                  clientDetailsForm.formState.errors.day ? "true" : "false"
                }
              />

              <input
                {...clientDetailsForm.register("year", {
                  pattern: /^[0-9]{0,4}$/,
                  validate: (value) => {
                    if (value || value + "" === "0") {
                      const year = parseInt(String(value), 10);
                      return year >= 0 && year < 10000;
                    }
                    return true;
                  },
                })}
                type="text"
                placeholder="Year"
                className="note-title-editor-input year"
                aria-invalid={
                  clientDetailsForm.formState.errors.year ? "true" : "false"
                }
              />

              {clientDetailsForm.formState.errors.day?.type === "pattern" && (
                <div className="error-message">
                  Day can contain one or two digits
                </div>
              )}
              {(clientDetailsForm.formState.errors.day?.type === "max" ||
                clientDetailsForm.formState.errors.day?.type === "min") && (
                <div className="error-message">
                  Day is a number from 1 to 31
                </div>
              )}
              {clientDetailsForm.formState.errors.day?.type === "validate" && (
                <div className="error-message">
                  There is no such date in the specified month
                </div>
              )}
              {(clientDetailsForm.formState.errors.year?.type === "validate" ||
                clientDetailsForm.formState.errors.year?.type ===
                  "pattern") && (
                <div className="error-message">
                  Year is a number from 0 to 9999
                </div>
              )}
            </div>

            <div className="actions">
              <SaveConfirmButton
                isSaving={props.isSaving}
                isDisabled={!isFormReady}
              />
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default NoteTitleEditor;
