import * as NoteActions from "../slices/noteSlice";
import { takeEvery, put, takeLatest, call } from "redux-saga/effects";
import * as noteApi from "../services/api/noteApi";
import * as loggly from "../services/logger";
import { RETRY_MAX_TRIES, RETRY_SHORT_DELAY } from "../config";
import mixpanelService, { TrackEvents } from "../services/mixpanel";
import { formatDate } from "../helpers/dateFormatHelper";
import { retry } from "./extensions";

function* handleClientDetailsUpdateRequest(action: ReturnType<typeof NoteActions.updateClientDetails>) {
  mixpanelService.trackEvent(TrackEvents.ClientDetailsUpdated, {
    "client id": action.payload.clientId,
    "client name": action.payload.clientName,
    "old name": action.payload.oldName,
    "new name": action.payload.name,
    "old dob": action.payload.oldBirthDate ? formatDate(action.payload.oldBirthDate) : null,
    "new dob": action.payload.birthDate ? formatDate(action.payload.birthDate): null,
  });

  yield retry(
    RETRY_MAX_TRIES,
    RETRY_SHORT_DELAY,
    noteApi.updateClientDetails,
    action.payload.clientId,
    action.payload.name,
    action.payload.birthDate
  );

  yield put(
    NoteActions.updatedClientDetails(action.payload.clientId, action.payload.name, action.payload.birthDate)
  );
}

function* handleNoteUpdateRequest(action: ReturnType<typeof NoteActions.updateNote>) {
  mixpanelService.trackEvent(TrackEvents.NotesUpdated, {
    "client id": action.payload.clientId,
    "client name": action.payload.clientName,
    action: action.payload.isAdded ? "new note" : "edit existing note",
    "total note length ": `${action.payload.plainText}`.length,
  });

  yield call(
    noteApi.updateNote,
    action.payload.clientId,
    action.payload.text
  );
  loggly.log(`Note for client ${action.payload.clientId} was updated`);
}

function* handleNoteSuccess() {}

function* handleNoteFailed() {}

function* handleNoteLoadRequest(action: ReturnType<typeof NoteActions.loadNote>) {
  const notes: noteApi.NotesResponse = yield retry(
    3,
    10000,
    noteApi.getNotes,
    action.payload.clientId
  );
  if (notes && notes.lastUpdated) {
    notes.lastUpdated = new Date(notes.lastUpdated + "Z");
  }
  yield put(NoteActions.noteLoaded(notes));
}

export default function* noteRoot() {
  yield takeEvery(NoteActions.updateNote.type, handleNoteUpdateRequest);
  yield takeEvery(NoteActions.noteUpdateSuccess.type, handleNoteSuccess);
  yield takeEvery(NoteActions.noteUpdateFailed.type, handleNoteFailed);
  yield takeLatest(NoteActions.loadNote.type, handleNoteLoadRequest);
  yield takeEvery(NoteActions.updateClientDetails.type, handleClientDetailsUpdateRequest);
}
