import * as React from "react";
import { ClientListSortFieldEnum } from "../../../services/api/clientListApi";
import { SortingIcon } from "./SortingIcon";

export interface ITableHeaderCellProps {
  clickColumnHeader?: (field: ClientListSortFieldEnum) => void;
  currentSortField: ClientListSortFieldEnum;
  sortingOrder: string;
  title: string;
  field: ClientListSortFieldEnum;
  className?: string;
}

export function TableHeaderCell(props: ITableHeaderCellProps) {
  return (
    <th
      className={props.className}
      onClick={() =>
        props.clickColumnHeader && props.clickColumnHeader(props.field)
      }
    >
      {props.currentSortField === props.field && (
        <SortingIcon sortingOrder={props.sortingOrder} />
      )}
      {props.title}
    </th>
  );
}
