import api from "./api";
import { Response } from "./api";

export type AppSettingsResponse = {
  urlToReleaseNotesPage: string;
  urlToFAQPage: string;
  urlToFeedbackPage: string;
  dashboardEnabled: boolean;
  advisorBlockClientTier: number;
  blockHighValuedClientHelpUrl: string;
  tutorialVideosUrl: string;
  tutorialLifecycleVideoUrl: string;
  autoChatMessageDelay: number;
  inChatMediaSendImagesEnabled: boolean;
  monthlyEarningsGoalWidgetEnabled: boolean;
  monthlyEarningsGoalWidgetLearnMoreUrl: string;
};

export interface SystemSettings {
  google_app_id:      string;
  fb_app_id:          string;
  mixpanel_token:     string;
  wem_fb_vapid_key:   string;
  wem_fb_api_key:     string;
  wem_fb_project_id:  string;
  wem_fb_sender_id:   string;
  wem_fb_app_id:      string;
  wem_fs_project_id:  string;
  wem_fs_api_key:     string;
  wem_fs_sender_id:   string;
  wem_fs_app_id:      string;
}


export function getSystemSettings():Promise<SystemSettings> {
  return api
    .get<SystemSettings>("/client_settings/wem")
    .then((response) => response.data);
}


export function getAppSettings(): Promise<AppSettingsResponse> {
  // TODO: replace this MOCK
  return Promise.resolve({
    urlToReleaseNotesPage: "https://lp.kasamba.com/advisor-suite-release-notes",
    urlToFAQPage: "https://lp.kasamba.com/FAQ",
    urlToFeedbackPage: "https://docs.google.com/forms/d/e/1FAIpQLScigFWekF3uUca1voMQ_S-xNdx5OY1Y7xaNoGpfYEdVG8AIHg/viewform?embedded=true",
    dashboardEnabled: true,
    advisorBlockClientTier: 3,
    blockHighValuedClientHelpUrl: "https://www.kasamba.com/lp/advisor-quality-program/managing-client-relationships/",
    tutorialVideosUrl: "https://vimeo.com/552328482/d779397d4a",
    tutorialLifecycleVideoUrl: "https://vimeo.com/552331666/11b12ee492",
    autoChatMessageDelay: 1000,
    inChatMediaSendImagesEnabled: true,
    monthlyEarningsGoalWidgetEnabled: false,
    monthlyEarningsGoalWidgetLearnMoreUrl: "https://www.kasamba.com/lp/advisor-bonus-program"
  });
  return api
    .get<AppSettingsResponse>("/api/app/settings?d=" + new Date().getTime())
    .then((response) => response.data);
}

export function getAppVersionOnServer(): Promise<Response<string>> {
  // TODO: replace this MOCK
  return Promise.resolve({
    result: "1.21.0402.1"
  });
  return api
    .get<Response<string>>("/api/app/version?d=" + new Date().getTime())
    .then((response) => response.data);
}
